body {
    margin: 0;
    color: #333;
    font-family: sans-serif;
}
.header {
    padding: 0.2rem;
    color: #fff;
    background-color: #000;
}
.header.secondary {
    background-color: #333;
}
nav {
    max-width: 62rem;
    margin: 1.5rem auto;
}
ul {
    display: flex;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}
.header li {
    margin-right: 1rem;
}
.header.secondary li:nth-last-child(3) {
    margin-right: auto;
}
.header a {
    color: #fff;
    text-decoration: none;
}
.header.home a[href='/'],
.header.page-router a[href$='/page-router'],
.header.profile a[href$='/profile'],
.header.edge-profile a[href$='/edge-profile'],
.header.profile-middleware a[href$='/profile-middleware'],
.header.profile-api a[href$='/profile-api'],
.header.edge-profile-api a[href$='/edge-profile-api'],
.header a.active {
    color: #888;
}
.header button {
    font-size: 1rem;
    color: #fff;
    cursor: pointer;
    border: none;
    background: none;
}
.container {
    max-width: 62rem;
    margin: 1.5rem auto;
}
